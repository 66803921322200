import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { get } from "lodash";
import { LogoutUser, logoutReset } from "../../actions/onLoadFunctionAction";
import history from "../../history";
import { LOCAL_STORAGE_KEYS, LOGGED_IN, RouteName, VERSION_KEY, VERSION_VALUE } from "../../constants/Constants";
import storage from "../../services/storage";
import { loginSuccess, verifyOtpSuccess } from "../../actions/loginAction";
import { resetAllSessionListReducerObj } from "../../actions/sessionListAction";
import { resetAllSessionDetailReducerObj } from "../../actions/sessionDetailAction";
import { resetActiveUsersReducerObj } from "../../actions/activeUsersActions";

const Logout = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { onLoadData } = useSelector((state) => state);
  const { logoutLoading, logoutData, logoutError } = onLoadData;

  useEffect(() => {
    const payload = {
      isLogoutAll: false,
      autoLogout: get(location, 'state.AUTOLOGOUT', false)
    };
    if (!logoutLoading && !logoutData && !logoutError) {
      dispatch(LogoutUser(payload));
    }
  }, []);

  useEffect(() => {
    if (logoutData) {
      const userLanguage = storage.get(LOCAL_STORAGE_KEYS.USERS_LANGUAGE);
      storage.clearAll();
      storage.set(LOCAL_STORAGE_KEYS.USERS_LANGUAGE, userLanguage);
      storage.set(LOCAL_STORAGE_KEYS.SWITCH_TO_NEW_ADMIN, true); // after login/logout by default user redirect to new portal
      localStorage.setItem(LOGGED_IN, "0")
      storage.set(VERSION_KEY, VERSION_VALUE);
      dispatch(resetAllSessionListReducerObj());
      dispatch(resetAllSessionDetailReducerObj());
      dispatch(resetActiveUsersReducerObj());
      history.push(RouteName.LOGIN);
      dispatch(logoutReset())
      dispatch(verifyOtpSuccess(false))
      dispatch(loginSuccess(false))
    }
  }, [logoutData]);

  return (
    <div className="full-height-loader">
      <div className="full-height-loader-in">
        <div className="full-height-loader-box">
          <div className="loader">
            <span className="for-icon icon-loader spin"></span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Logout;
